import { useFormContext } from 'react-hook-form';
import { CommonInputProps } from 'src/types';
import { ErrorText } from 'src/components';
import { TextInput } from './InputStyles';

interface InputProps extends CommonInputProps {
  placeholder: string;
  name: string;
}

export function Input({ placeholder, name, options }: InputProps) {
  const {
    formState: { errors },
    register
  } = useFormContext();

  return (
    <div>
      <TextInput
        placeholder={options?.required ? `${placeholder} *` : placeholder}
        error={errors[name]}
        {...register(name, { ...options })}
      />
      {errors[name] && errors[name].message ? <ErrorText>{errors[name].message}</ErrorText> : null}
    </div>
  );
}
