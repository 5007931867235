import { Text, TextArea } from 'src/components';
import { EFormProps } from 'src/types';
import { LABELS, PLACEHOLDERS, requiredOption } from 'src/utils';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export function Comentarios() {
  return (
    <Wrapper>
      <Text bold>{LABELS.COMENTARIOS}</Text>
      <TextArea placeholder={PLACEHOLDERS.COMENTARIOS} name={EFormProps.COMENTARIOS} options={requiredOption} />
    </Wrapper>
  );
}
