import { TipoContacto, Trato, InputsWrapper, Input } from 'src/components';
import { EFormProps } from 'src/types';
import { PLACEHOLDERS, emailOptions, onlyLettersOption, phoneOptions, requiredAndLettersOptions } from 'src/utils';

export const FormBase = () => {
  return (
    <>
      <TipoContacto />
      <Trato />
      <InputsWrapper>
        <Input name={EFormProps.NOMBRE} placeholder={PLACEHOLDERS.NOMBRE} options={requiredAndLettersOptions} />
        <Input
          name={EFormProps.PRIMER_APELLIDO}
          placeholder={PLACEHOLDERS.PRIMER_APELLIDO}
          options={requiredAndLettersOptions}
        />
        <Input
          name={EFormProps.SEGUNDO_APELLIDO}
          placeholder={PLACEHOLDERS.SEGUNDO_APELLIDO}
          options={onlyLettersOption}
        />
        <Input name={EFormProps.TELEFONO} placeholder={PLACEHOLDERS.TELEFONO} options={phoneOptions} />
        <Input name={EFormProps.EMAIL} placeholder={PLACEHOLDERS.EMAIL} options={emailOptions} />
      </InputsWrapper>
    </>
  );
};
