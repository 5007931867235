import { IAttClienteForm, IContactoForm, IMasInformacionForm, IOfertaForm, ITestDriveForm } from 'src/types';
import { COMMON_DEFAULT_VALUES } from './constants';
import { EFormProps } from 'src/types';

export const ATT_CLIENTE_DEFAULT_VALUES: IAttClienteForm = {
  [EFormProps.TYPE]: 'atención',
  [EFormProps.ID_FORM]: 'Atención al cliente',
  [EFormProps.COMENTARIOS]: '',
  ...COMMON_DEFAULT_VALUES
};

export const CONTACTO_DEFAULT_VALUES: IContactoForm = {
  [EFormProps.TYPE]: 'contacto',
  [EFormProps.ID_FORM]: 'Contacto',
  [EFormProps.COMENTARIOS]: '',
  ...COMMON_DEFAULT_VALUES
};

export const MAS_INFORMACION_DEFAULT_VALUES: IMasInformacionForm = {
  [EFormProps.TYPE]: 'masinfo',
  [EFormProps.ID_FORM]: 'Más información',
  [EFormProps.MODELO]: '',
  ...COMMON_DEFAULT_VALUES
};

export const OFERTA_DEFAULT_VALUES: IOfertaForm = {
  [EFormProps.TYPE]: 'oferta',
  [EFormProps.ID_FORM]: 'Oferta',
  [EFormProps.VN_VO]: 'VN',
  [EFormProps.MODELO]: '',
  ...COMMON_DEFAULT_VALUES
};

export const TEST_DRIVE_DEFAULT_VALUES: ITestDriveForm = {
  [EFormProps.TYPE]: 'testdrive',
  [EFormProps.ID_FORM]: 'Prueba de conducción',
  [EFormProps.VN_VO]: 'VN',
  [EFormProps.MODELO]: '',
  ...COMMON_DEFAULT_VALUES
};
