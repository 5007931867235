import { RadioButtonGroup } from 'src/components';
import { EFormProps } from 'src/types';
import { RADIO_BUTTONS, requiredOption } from 'src/utils';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const common = {
  name: EFormProps.TIPO_CONTACTO,
  options: requiredOption
};

export function TipoContacto() {
  return (
    <Wrapper>
      <RadioButtonGroup
        firstOption={{
          ...common,
          ...RADIO_BUTTONS.TIPO_CONTACTO.person,
          isChecked: true
        }}
        secondOption={{ ...common, ...RADIO_BUTTONS.TIPO_CONTACTO.company }}
      />
    </Wrapper>
  );
}
