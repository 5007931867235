import styled from 'styled-components';

export const RadioInput = styled.input`
  display: none;
  vertical-align: middle;
  cursor: pointer;

  & label:before {
    border: 2px solid orange;
  }
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: always;
  gap: 10px;
`;

export const Radio = styled.div<{ checked: boolean; error: boolean }>`
  width: 23px;
  height: 23px;
  background: var(--white);
  border-radius: 50%;
  border: ${(props) => (props.checked ? '2px solid var(--black)' : '2px solid var(--light-grey)')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 7px; */

  &::after {
    content: '';
    width: ${(props) => (props.checked ? '50%' : '35%')};
    height: ${(props) => (props.checked ? '50%' : '35%')};
    border-radius: 50%;
    display: block;
    background: ${(props) => (props.checked ? 'var(--black)' : 'var(--light-grey)')};
  }
`;

export const LabelText = styled.p<{ checked: boolean; error: boolean }>`
  display: inline;
  font-size: 14px;
  line-height: 1;
  color: ${(props) => (props.error ? 'var(--error)' : 'var(--grey)')};
`;
