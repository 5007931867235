import { Select } from 'src/components';
import { PLACEHOLDERS, requiredOption } from 'src/utils';
import { useVehicles } from 'src/hooks/useVehicles';
import styled from 'styled-components';
import { EFormProps } from 'src/types';
import { FC } from 'react';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

interface ISeleccionModeloProps {
  vovnValue?: string;
}

export const SeleccionModelo: FC<ISeleccionModeloProps> = ({ vovnValue }) => {
  const { formattedVehicles } = useVehicles(vovnValue);

  return (
    <Wrapper>
      <Select
        name={EFormProps.MODELO}
        selectOptions={formattedVehicles}
        placeholder={PLACEHOLDERS.MODELO}
        options={requiredOption}
      />
    </Wrapper>
  );
};
