import { useState } from 'react';
import { RadioButton } from 'src/components';
import { CommonInputProps } from 'src/types';
import styled from 'styled-components';
import { RadioButtonProps } from '../RadioButton';

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
`;

interface RadioButtonGroupProps extends CommonInputProps {
  firstOption: RadioButtonProps;
  secondOption: RadioButtonProps;
}

export function RadioButtonGroup({ firstOption, secondOption }: RadioButtonGroupProps) {
  const [firstOptionChecked, setFirstOptionChecked] = useState(() => firstOption.isChecked);
  const [secondOptionChecked, setSecondOptionChecked] = useState(() => secondOption.isChecked);

  const handleFirstOptionClick = () => {
    setFirstOptionChecked(true);
    setSecondOptionChecked(false);
  };

  const handleSecondOptionClick = () => {
    setSecondOptionChecked(true);
    setFirstOptionChecked(false);
  };

  return (
    <>
      <Wrapper>
        <RadioButton {...firstOption} onClick={handleFirstOptionClick} isChecked={firstOptionChecked} />
        <RadioButton {...secondOption} onClick={handleSecondOptionClick} isChecked={secondOptionChecked} />
      </Wrapper>
    </>
  );
}
