import { RegisterOptions } from 'react-hook-form';

export interface ILabelValue {
  label: string;
  value: string;
}

export interface CommonInputProps {
  options?: RegisterOptions<any>;
}

interface IApiRes {
  info: {
    timestamp: Date;
  };
}

export interface IDealer {
  id: number;
  codigoConcesion: string;
  kvpsInstalacion: string;
  codigoInstalacion: string;
  grupoInversor: string;
  razonSocial: string;
  nombreComercial: string;
  cif: string;
  provincia: string;
  poblacion: string;
  direccion: string;
  cp: number;
  latitud: number;
  longitud: number;
  telefono: number;
  pv: boolean;
  vn: boolean;
  vo: boolean;
  marca: boolean;
  categoriaInstalacion: string;
  gerenteInstalacion: string;
  emailGerenteInstalacion: string;
  emailRgpd: string;
  urlPoliticaPrivacidad: string;
  urlPoliticaCookies: string;
}

export interface IGetDealersRes extends IApiRes {
  dealers: IDealer[];
}

export interface IVehicle {
  nombre: string;
  vn: boolean;
  vo: boolean;
}

export interface IGetVehiclesRes extends IApiRes {
  vehiculos: IVehicle[];
}

export enum EFormProps {
  TYPE = 'TYPE',
  ID_FORM = 'ID_FORM',
  ID_CONCESION = 'ID_CONCESION',
  CODIGO_CONCESION = 'CODIGO_CONCESION',
  NOMBRE_CONCESION = 'NOMBRE_CONCESION',
  LEGAL_1 = 'LEGAL_1',
  LEGAL_2 = 'LEGAL_2',
  TIPO_CONTACTO = 'TIPO_CONTACTO',
  TRATO = 'TRATO',
  NOMBRE = 'NOMBRE',
  PRIMER_APELLIDO = 'PRIMER_APELLIDO',
  SEGUNDO_APELLIDO = 'SEGUNDO_APELLIDO',
  TELEFONO = 'TELEFONO',
  EMAIL = 'EMAIL',
  INSTALACION = 'INSTALACION',
  COMENTARIOS = 'COMENTARIOS',
  VN_VO = 'VN_VO',
  MODELO = 'MODELO'
}

interface ICommonForm {
  [EFormProps.TYPE]: string;
  [EFormProps.ID_FORM]: string;
  [EFormProps.ID_CONCESION]: string;
  [EFormProps.CODIGO_CONCESION]: string;
  [EFormProps.NOMBRE_CONCESION]: string;
  [EFormProps.LEGAL_1]: string;
  [EFormProps.LEGAL_2]: string;
  [EFormProps.TIPO_CONTACTO]: string;
  [EFormProps.TRATO]: string;
  [EFormProps.NOMBRE]: string;
  [EFormProps.PRIMER_APELLIDO]: string;
  [EFormProps.SEGUNDO_APELLIDO]: string;
  [EFormProps.TELEFONO]: string;
  [EFormProps.EMAIL]: string;
  [EFormProps.INSTALACION]: string;
}

export interface IAttClienteForm extends ICommonForm {
  [EFormProps.COMENTARIOS]: string;
}

export interface IContactoForm extends ICommonForm {
  [EFormProps.COMENTARIOS]: string;
}

export interface IMasInformacionForm extends ICommonForm {
  [EFormProps.MODELO]: string;
}

export interface IOfertaForm extends ICommonForm {
  [EFormProps.VN_VO]: string;
  [EFormProps.MODELO]: string;
}

export interface ITestDriveForm extends ICommonForm {
  [EFormProps.VN_VO]: string;
  [EFormProps.MODELO]: string;
}

export type TForm = IAttClienteForm | IContactoForm | IMasInformacionForm | IOfertaForm | ITestDriveForm;
