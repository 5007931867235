import axios, { AxiosResponse } from 'axios';
import { IDealer, IGetDealersRes } from 'src/types';

const url = `${process.env.REACT_APP_API_URL}/api/getDealers/formulario`;

export const getDealers = async (): Promise<IDealer[] | null> => {
  try {
    const response: AxiosResponse<IGetDealersRes> = await axios.get(url);
    return response.data.dealers;
  } catch (error) {
    console.error(error);
    return null;
  }
};
