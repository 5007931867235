import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormBase } from './components/FormBase';
import { CTA, SeleccionInstalacion, SeleccionModelo, Title, InputsWrapper } from 'src/components';
import { sendData } from 'src/requests';
import InfoError from 'src/components/InfoError';
import { useDealer } from 'src/hooks/useDealer';
import { objectIsEmpty, sendHeight, setDealerData } from 'src/utils/functions';
import Legal from 'src/components/Legal';
import { IMasInformacionForm } from 'src/types';
import { MAS_INFORMACION_DEFAULT_VALUES } from 'src/utils/default-form-values';
import ErrorMessage from 'src/components/fields/ErrorMessage';
import SuccessMessage from 'src/components/fields/SuccessMessage';
import { FORM_ORDER_2 } from 'src/utils';

export const MasInformacionForm = () => {
  const methods = useForm({
    defaultValues: MAS_INFORMACION_DEFAULT_VALUES
  });
  const { handleSubmit, formState, setValue, reset } = methods;
  const { dealer, error, setError } = useDealer();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const submit = async (data: IMasInformacionForm) => {
    const res = await sendData(data, setError, FORM_ORDER_2);
    if (res?.status === 'OK') {
      setIsSubmitted(true);
      var timeoutID:any;
      sendHeight(timeoutID, 489);
    }
  };

  useEffect(() => {
    if (dealer) setDealerData(dealer, setValue);
  }, [dealer]);

  const handleGoBack = () => {
    reset();
    setIsSubmitted(false);
  };

  return (
    <FormProvider {...methods}>
      <Title>Más información</Title>
      {error ? (
        <ErrorMessage />
      ) : isSubmitted ? (
        <SuccessMessage goBack={handleGoBack} />
      ) : (
        <>
          {!objectIsEmpty(formState.errors) && <InfoError />}
          <form onSubmit={handleSubmit(submit)}>
            <FormBase />
            <InputsWrapper>
              <SeleccionInstalacion />
              <SeleccionModelo />
            </InputsWrapper>
            <CTA label="Enviar" />
          </form>
          {dealer && (
            <Legal
              name={dealer.razonSocial}
              emailRgpd={dealer.emailRgpd}
              urlPoliticaPrivacidad={dealer.urlPoliticaPrivacidad}
              urlPoliticaCookies={dealer.urlPoliticaCookies}
            />
          )}
        </>
      )}
    </FormProvider>
  );
};
