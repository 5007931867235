import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 2rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 2rem;
`;

export const Anchor = styled.a`
  display: block;
  font-size: 12px;
  color: black;
  text-decoration: underline;
  font-size: 12px;
  margin-bottom: 10px;
  display: inline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
  &:visited {
    color: black;
    text-decoration: underline;
  }
`;

export const SmallAnchor = styled(Anchor)`
  font-size: 11px;
`;

export const SmallText = styled.p<{ bold?: boolean }>`
  font-size: 11px;
  display: block;
  margin: 0;
  -webkit-font-smoothing: antialiased;
`;
