import styled from 'styled-components';

export const Title = styled.h1`
    font-weight: 700;
    font-style: normal;
    font-size: 1.8rem;
    margin-bottom: 72px;
    margin-top: 50px;
`;

export const Text = styled.p<{ bold?: boolean; error?: boolean }>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  color: ${(props) => (props.error ? 'var(--error)' : 'var(--grey)')};
`;


export const ErrorText = styled.p`
  color: var(--error);
`;
