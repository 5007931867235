import styled, { createGlobalStyle } from 'styled-components';
import AudiExtendedNormal from './fonts/AudiExtendedNormal.woff';

export const GlobalStyle = createGlobalStyle`
  :root {
    --grey: #636363;
    --light-grey: #b8b8b8;
    --black: #000000;
    --white: #ffffff;
    --error: #d30300;
  }

  @font-face {
    font-family: 'AudiType';
    src: url(${AudiExtendedNormal}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'AudiType', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-weight: 400;
    background-color: #fff;
    text-align: left;
  }

  p, input, select {
    font-family: 'AudiType', "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
`;
interface BreakPoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const breakpoints: BreakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const mediaQueries = (key: keyof typeof breakpoints) => {
  return (style: TemplateStringsArray | string) => `@media (min-width: ${breakpoints[key]}px) { ${style} }`;
};

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  ${mediaQueries('sm')`
      max-width: 540px;
    `}
  ${mediaQueries('md')`
      max-width: 720px;
    `}
    ${mediaQueries('lg')`
      max-width: 960px;
    `}
    ${mediaQueries('xl')`
      max-width: 1140px;
    `}
`;
