import axios, { AxiosResponse } from 'axios';
import { IGetVehiclesRes, IVehicle } from 'src/types';

const url = `${process.env.REACT_APP_API_URL}/api/getVehiculos`;

export const getVehicles = async (): Promise<IVehicle[] | null> => {
  try {
    const response: AxiosResponse<IGetVehiclesRes> = await axios.get(url);
    return response.data.vehiculos;
  } catch (error) {
    console.error(error);
    return null;
  }
};
