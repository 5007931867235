import { isValidPhoneNumber } from 'libphonenumber-js';
import { RegisterOptions } from 'react-hook-form';
import { EFormProps } from 'src/types';
import isEmail from 'validator/lib/isEmail';

export const COMMON_DEFAULT_VALUES = {
  [EFormProps.ID_CONCESION]: '',
  [EFormProps.CODIGO_CONCESION]: '',
  [EFormProps.NOMBRE_CONCESION]: '',
  [EFormProps.LEGAL_1]: 'TRUE',
  [EFormProps.LEGAL_2]: 'TRUE',
  [EFormProps.TIPO_CONTACTO]: 'PARTICULAR',
  [EFormProps.TRATO]: '',
  [EFormProps.NOMBRE]: '',
  [EFormProps.PRIMER_APELLIDO]: '',
  [EFormProps.SEGUNDO_APELLIDO]: '',
  [EFormProps.TELEFONO]: '',
  [EFormProps.EMAIL]: '',
  [EFormProps.INSTALACION]: ''
};

export const requiredOption: Partial<RegisterOptions> = {
  required: true
};

const regExp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\s+]+$/;

export const onlyLettersOption: Partial<RegisterOptions> = {
  pattern: { value: regExp, message: 'Solo se aceptan letras.' }
};

export const requiredAndLettersOptions: RegisterOptions = {
  ...requiredOption,
  ...onlyLettersOption
};

export const phoneOptions: RegisterOptions = {
  ...requiredOption,
  maxLength: {
    value: 9,
    message: 'Máximo 9 dígitos.'
  },
  minLength: {
    value: 8,
    message: 'Faltan números.'
  },
  validate: (value) => {
    if (isValidPhoneNumber(`+34${value}`)) {
      return true;
    }
    return 'Número no válido.';
  }
};

export const emailOptions: RegisterOptions = {
  ...requiredOption,
  validate: (value) => {
    if (isEmail(value)) {
      return true;
    } else {
      return 'Email no válido.';
    }
  }
};

export const PLACEHOLDERS = {
  [EFormProps.NOMBRE]: 'Nombre',
  [EFormProps.PRIMER_APELLIDO]: 'Apellido 1',
  [EFormProps.SEGUNDO_APELLIDO]: 'Apellido 2',
  [EFormProps.TELEFONO]: 'Teléfono',
  [EFormProps.EMAIL]: 'Email',
  [EFormProps.INSTALACION]: 'Seleccione la instalación *',
  [EFormProps.COMENTARIOS]: 'Escriba aquí...',
  [EFormProps.MODELO]: 'Modelo*'
};

export const LABELS = {
  [EFormProps.TRATO]: 'Trato *:',
  [EFormProps.COMENTARIOS]: 'Comentarios *:'
};

export const RADIO_BUTTONS = {
  [EFormProps.TIPO_CONTACTO]: {
    person: {
      label: 'Particular',
      value: 'PARTICULAR'
    },
    company: {
      label: 'Empresa',
      value: 'EMPRESA'
    }
  },
  [EFormProps.TRATO]: {
    male: {
      label: 'Sr.',
      value: 'SR'
    },
    female: {
      label: 'Sra.',
      value: 'SRA'
    }
  }
};

// Forms: Contacto, Att. Cliente
export const FORM_ORDER_1 = [
  EFormProps.CODIGO_CONCESION,
  EFormProps.TYPE,
  EFormProps.TIPO_CONTACTO,
  EFormProps.TRATO,
  EFormProps.NOMBRE,
  EFormProps.EMAIL,
  EFormProps.TELEFONO,
  EFormProps.INSTALACION,
  EFormProps.COMENTARIOS
];

// Forms: Prueba conducción, Más información, Solicitar oferta
export const FORM_ORDER_2 = [
  EFormProps.CODIGO_CONCESION,
  EFormProps.TYPE,
  EFormProps.TIPO_CONTACTO,
  EFormProps.TRATO,
  EFormProps.NOMBRE,
  EFormProps.EMAIL,
  EFormProps.TELEFONO,
  EFormProps.VN_VO,
  EFormProps.INSTALACION,
  EFormProps.MODELO
];
