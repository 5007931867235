import { mediaQueries } from 'src/global-styles';
import styled from 'styled-components';

const columnSize = 31;
export const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  padding-top: 24px;
  margin-bottom: 24px;
  align-items: start;
  ${mediaQueries('md')`
    grid-template-columns: repeat(2, 31%);
  `}
`;

export const ComentariosWrapper = styled.div`
  width: 100%;
  ${mediaQueries('md')`
    width: 62%;
  `}
`;

export const ErrorContainer = styled.div`
  width: 62%;
  border: 1px solid var(--error);
  padding: 10px;
  margin-bottom: 20px;

  & p {
    margin: 0;
  }
`;
