import { useEffect, useState } from 'react';
import { ILabelValue, IVehicle } from 'src/types';
import { getVehicles } from '../requests/get-vehicles';

const formatVehicles = (vehicles: IVehicle[]) => {
  return vehicles.map((elm) => {
    return {
      label: elm.nombre,
      value: elm.nombre
    };
  });
};

export const useVehicles = (vovnValue?: string) => {
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [formattedVehicles, setFormattedVehicles] = useState<ILabelValue[]>([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getVehicles();
      if (data) setVehicles(data);
    };
    getData();
  }, []);

  useEffect(() => {
    const _vehicles = vehicles.filter((vehicle) => {
      if (!vovnValue) return true;
      if (vovnValue === 'VN') return vehicle.vn;
      else return vehicle.vo;
    });

    const formatted = formatVehicles(_vehicles);
    setFormattedVehicles(formatted);
  }, [vehicles, vovnValue]);

  return {
    formattedVehicles
  };
};
