import { FC } from 'react';
import { Wrapper, Anchor, SmallAnchor, SmallText } from 'src/components';

interface ILegal {
  name: string;
  emailRgpd: string;
  urlPoliticaPrivacidad: string;
  urlPoliticaCookies: string;
}

const Legal: FC<ILegal> = ({ name, emailRgpd, urlPoliticaPrivacidad, urlPoliticaCookies }) => {
  return (
    <Wrapper>
      <Anchor href={urlPoliticaPrivacidad} target="_blank">
        Enviando este formulario aceptas la Política de Datos y Privacidad.
      </Anchor>
      <SmallText>* Campos obligatorios</SmallText>
      <SmallText>
        Según lo previsto por la normativa de protección de datos, te informamos que el responsable del tratamiento de
        tus datos personales será <b>{name}</b>. Tus datos personales serán tratados con la finalidad de dar
        cumplimiento a tu petición de contacto y, en caso de que nos hayas dado tu consentimiento expreso, para las
        finalidades descritas en los consentimientos adicionales. Te informamos que puedes ejercer los derechos que te
        confiere la normativa de protección de datos mediante solicitud escrita a{' '}
        <SmallAnchor href={`mailto:${emailRgpd}`} target="_blank">
          {emailRgpd}
        </SmallAnchor>
        .
      </SmallText>
      <SmallText>
        Puedes consultar con mayor detalle la Información adicional sobre Protección de Datos aquí:{' '}
        <SmallAnchor href={urlPoliticaPrivacidad} target="_blank">
          Política de Datos y Privacidad.
        </SmallAnchor>{' '}
      </SmallText>
      <SmallText>
        Puedes consultar con mayor detalle la Información y Configuración sobre cookies aquí:{' '}
        <SmallAnchor href={urlPoliticaCookies} target="_blank">
          Política de Cookies.
        </SmallAnchor>
      </SmallText>
    </Wrapper>
  );
};

export default Legal;
