import { UseFormSetValue } from 'react-hook-form';
import { EFormProps, IDealer } from 'src/types';

export const objectIsEmpty = (obj: any) => Object.keys(obj).length === 0;

// TODO: any - FieldValues
export const setDealerData = (dealer: IDealer, setValue: UseFormSetValue<any>) => {
  if (dealer) {
    setValue(EFormProps.ID_CONCESION, dealer.codigoConcesion);
    setValue(EFormProps.CODIGO_CONCESION, dealer.codigoConcesion);
    setValue(EFormProps.NOMBRE_CONCESION, dealer.nombreComercial);
  }
};


export const sendHeight = (timeoutID:any, customHeight?:number) => {
  clearTimeout(timeoutID);

  timeoutID = setTimeout(function () {
    // var height_to_pass = 1394;
    let height_to_pass = Math.round(customHeight || document.querySelector('.container')?.clientHeight || 1394) + 120;
    let dataToSend = {
      'height': height_to_pass + 'px',
      'url': window.location.href
    };
    window.parent.postMessage(JSON.stringify(dataToSend), '*');
  }, 250);
};
