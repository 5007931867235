import { useFormContext } from 'react-hook-form';
import { CommonInputProps } from 'src/types';
import styled from 'styled-components';

const SelectWrapper = styled.select<{ error: boolean }>`
  border: none;
  border-bottom: ${(props) => (props.error ? '1px solid var(--error)' : '1px solid var(--grey)')};
  /* background: var(--white); */
  color: ${(props) => (props.error ? 'var(--error) !important' : 'var(--grey)')};
  display: inline-block;
  font-size: 13px;
  border-radius: 0px;
  margin-top: 10px;
  /* margin-bottom: 20px; */
  min-width: 70px;
  width: 100%;
  padding: 0 8px 4px 8px;
  appearance: none;
  &:focus {
    border: none;
  }
  &:active {
    border: none;
  }
  &::placeholder {
    color: ${(props) => (props.error ? 'var(--error) !important' : 'var(--grey)')};
  }
`;

export interface SelectOption {
  label: string;
  value: string;
}
interface SelectProps extends CommonInputProps {
  selectOptions: SelectOption[];
  placeholder: string;
  name: string;
  disabled?: boolean;
}

export function Select({ selectOptions, placeholder, options, name, disabled }: SelectProps) {
  const {
    formState: { errors },
    register
  } = useFormContext();

  return (
    <SelectWrapper {...register(name, { ...options })} error={errors[name]} disabled={disabled}>
      <option value="">{placeholder}</option>
      {selectOptions.map((option) => {
        return (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        );
      })}
    </SelectWrapper>
  );
}
