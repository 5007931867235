import { FC } from 'react';

const ErrorMessage: FC = () => {
  return (
    <div>
      <p>Gracias por intentar contactar con nosotros.</p>
      <p>En estos momentos el formulario de contacto no está disponible. Por favor, vuelve a intentarlo más tarde.</p>
      <p>Disculpa las molestias.</p>
    </div>
  );
};

export default ErrorMessage;
