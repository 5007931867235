import { ReactNode } from 'react';
import { GlobalStyle, Container } from 'src/global-styles';

type PropsWithChildren<P> = P & { children?: ReactNode };

export const PageLayout = (props: PropsWithChildren<any>) => {
  return (
    <>
      <GlobalStyle />
      <Container>{props.children}</Container>
    </>
  );
};
