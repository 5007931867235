import { FC } from 'react';
import { CTA } from '../elements';
import styled from 'styled-components';
import { mediaQueries } from 'src/global-styles';
import icon from '../../assets/img/icon-contactSend.png';

interface SuccessMessageProps {
  goBack?: () => void;
}

const SuccessMessageWrapper = styled.div`
  & > input {
    width: auto;
  }
  .text-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
  }

  img {
    object-fit: cover;
  }
`;

const SuccessMessage: FC<SuccessMessageProps> = ({ goBack }) => {
  return (
    <SuccessMessageWrapper>
      <div className="text-container">
        <img src={icon} alt="success-icon" />
        <div>
          <p>Gracias por contactar con nosotros, tus respuestas han sido enviadas correctamente.</p>
          <p>Te responderemos lo antes posible.</p>
        </div>
      </div>
      <CTA onClick={goBack} label="Volver" />
    </SuccessMessageWrapper>
  );
};

export default SuccessMessage;
