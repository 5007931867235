import styled from 'styled-components';

export const TextInput = styled.input<{error: boolean}>`
  display: inline-block;
  border: none;
  border-bottom: ${(props) => (props.error ? '1px dotted var(--error)' : '1px solid var(--grey);')};
  width: 100%;
  padding: 0.375rem 0;
  font-size: 13px;
  line-height: 1.5;
  color: ${(props) => (props.error ? 'var(--error) !important' : 'var(--grey)')};
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
  align-self: flex-end;
  
  &::placeholder {
    color: ${(props) => (props.error ? 'var(--error) !important' : 'var(--grey)')};
  }
`;
