import { FC } from 'react';
import { ErrorContainer, ErrorText } from './elements';

const InfoError: FC = () => {
  return (
    <ErrorContainer>
      <ErrorText>Revise, por favor, la información que nos ha proporcionado.</ErrorText>
    </ErrorContainer>
  );
};

export default InfoError;
