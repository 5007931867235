import axios from 'axios';
import { EFormProps, IContactoForm, TForm } from 'src/types';

const url = `${process.env.REACT_APP_API_URL}/form/dealer/send`;

export const sendData = async (data: TForm, setError: React.Dispatch<React.SetStateAction<boolean>>, order: any) => {
  try {
    // TODO: replace provisional GET with POST
    // const response = await axios.post('https://pre-core-01.audi.es/form/dealer/send', data);
    const orderedData: any = {};
    const test: any = { ...data };
    order.forEach((field: any) => {
      if (test.hasOwnProperty(field)) {
        if (field === EFormProps.NOMBRE) {
          orderedData[field] = `${test[field]} ${test[EFormProps.PRIMER_APELLIDO]} ${
            test[EFormProps.SEGUNDO_APELLIDO]
          }`.toUpperCase();
          return;
        }
        orderedData[field] = test[field];
      }
    });
    const queryString = new URLSearchParams(orderedData as unknown as Record<string, string>).toString();
    const response = await axios.get(`${url}?${queryString}`);

    return response.data;
  } catch (err) {
    console.error(err);
    setError(true);
  }
};
