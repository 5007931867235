import { useFormContext } from 'react-hook-form';
import { RadioButtonGroup } from 'src/components';
import { Text } from 'src/components/elements/Texts';
import { EFormProps } from 'src/types';
import { LABELS, RADIO_BUTTONS, requiredOption } from 'src/utils';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 1rem;
`;

const common = {
  name: EFormProps.TRATO,
  options: requiredOption
};

export function Trato() {
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <Wrapper>
      <Text bold error={errors[EFormProps.TRATO]}>
        {LABELS.TRATO}
      </Text>
      <RadioButtonGroup
        firstOption={{ ...common, ...RADIO_BUTTONS.TRATO.male }}
        secondOption={{ ...common, ...RADIO_BUTTONS.TRATO.female }}
      />
    </Wrapper>
  );
}
