import { Select } from 'src/components';
import { useDealer } from 'src/hooks/useDealer';
import { EFormProps } from 'src/types';
import { PLACEHOLDERS, requiredOption } from 'src/utils';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

export function SeleccionInstalacion() {
  const { formattedDealers } = useDealer();

  return (
    <Wrapper>
      <Select
        name={EFormProps.INSTALACION}
        selectOptions={formattedDealers}
        placeholder={PLACEHOLDERS.INSTALACION}
        options={requiredOption}
      />
    </Wrapper>
  );
}
