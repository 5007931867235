import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IDealer, ILabelValue } from 'src/types';
import { getDealers } from '../requests/get-dealers';

const getDecodedString = (encodedString:string):string => encodedString.replace(/u([\dA-Fa-f]{4})/g, (match, hex) => String.fromCharCode(parseInt(hex, 16)));

const formatDealers = (dealers: IDealer[]) => {
  return dealers.map((elm) => {
    const instalacion = getDecodedString(`${elm.nombreComercial} - ${elm.direccion}, C.P. ${elm.cp} ${elm.poblacion}, ${elm.provincia}`);
    return {
      label: instalacion,
      value: instalacion
    };
  });
};

const getDealer = (dealers: IDealer[], partnerId: string) => {
  return dealers.find((elm) => elm.codigoConcesion === partnerId);
};

const getDealersToShow = (dealers: IDealer[], partnerId: string | null) => {
  if (partnerId) {
    const dealer = getDealer(dealers, partnerId);
    if (dealer) return dealers.filter((elm) => elm.codigoConcesion === dealer.codigoConcesion);
  }
  return [];
};

export const useDealer = () => {
  const [dealers, setDealers] = useState<IDealer[]>([]);
  const [dealer, setDealer] = useState<IDealer | undefined>(undefined);
  const [formattedDealers, setFormattedDealers] = useState<ILabelValue[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const partnerId = searchParams.get('partner_id');

  useEffect(() => {
    const getData = async () => {
      const data = await getDealers();
      if (data) setDealers(data);
      else setError(true);
      setLoading(false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (partnerId) {
      const _dealer = getDealer(dealers, partnerId);
      setDealer(_dealer);
    }
    const _dealers = getDealersToShow(dealers, partnerId);
    const formatted = formatDealers(_dealers);
    if(!formatted.length && !loading) setError(true);
    setFormattedDealers(formatted);
  }, [dealers, loading]);

  return {
    formattedDealers,
    dealer,
    error,
    setError
  };
};
