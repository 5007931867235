import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonInputProps } from 'src/types';
import { RadioInput, Label, Radio, LabelText } from '.';

export interface RadioButtonProps extends CommonInputProps {
  label: string;
  name: string;
  isChecked?: boolean;
  onClick?: () => void;
  value: string;
}

export function RadioButton({ name, label, isChecked, onClick, options, value }: RadioButtonProps) {
  const [checked, setChecked] = useState(() => (isChecked ? isChecked : false));
  const {
    formState: { errors },
    register
  } = useFormContext();

  useEffect(() => {
    if (typeof isChecked === 'boolean') {
      setChecked(isChecked);
    }
  }, [isChecked]);

  return (
    <Label>
      <RadioInput
        {...register(name, { ...options })}
        type="radio"
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }
          setChecked(!checked);
        }}
        value={value}
      />
      <Radio checked={checked} error={errors[name]} />
      <LabelText checked={checked} error={errors[name]}>
        {label}
      </LabelText>
    </Label>
  );
}
