import { CommonInputProps } from 'src/types';
import { mediaQueries } from 'src/global-styles';
import styled from 'styled-components';

const CTAButton = styled.input`
  color: white;
  background-color: #000;
  border: none;
  padding: 15px 10px;
  cursor: pointer;
  float: left;
  display: block;
  text-align: center;
  width: 100%;

  ${mediaQueries('sm')`
        width: 33.333%;
    `}
  ${mediaQueries('md')`
        width: 16.667%;
    `}
`;

interface CTAProps extends CommonInputProps {
  onClick?: () => void;
  label: string;
}

export function CTA({ onClick, label }: CTAProps) {
  return <CTAButton type="submit" onClick={onClick} placeholder={label} value={label} />;
}
