import { useFormContext } from 'react-hook-form';
import { CommonInputProps } from 'src/types';
import styled from 'styled-components';

const TextAreaComponent = styled.textarea<{ error: boolean }>`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-family: 'AudiType';
  line-height: 1.5;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f2f2f2;
  font-style: italic;
  min-height: 270px;
  border: ${(props) => (props.error ? '1px solid var(--error)' : 0)};
  border-radius: 0;
  color: #555;
  box-shadow: inset 0 0px 0px rgb(0 0 0 / 0%);
  resize: none;
`;

interface TextAreaProps extends CommonInputProps {
  placeholder: string;
  name: string;
}

export function TextArea({ placeholder, options, name }: TextAreaProps) {
  const {
    formState: { errors },
    register
  } = useFormContext();

  return <TextAreaComponent {...register(name, { ...options })} placeholder={placeholder} error={errors[name]} />;
}
