import { PageLayout } from './components';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AttClienteForm, ContactoForm, MasInformacionForm, OfertaForm, TestDriveForm } from './forms';
import { useEffect } from 'react';
import { sendHeight } from './utils';

const router = createBrowserRouter([
  {
    path: '/dealersforms/contacto',
    element: (
      <PageLayout>
        <ContactoForm />
      </PageLayout>
    )
  },
  {
    path: '/dealersforms/oferta-comercial',
    element: (
      <PageLayout>
        <OfertaForm />
      </PageLayout>
    )
  },
  {
    path: '/dealersforms/atencion-cliente',
    element: (
      <PageLayout>
        <AttClienteForm />
      </PageLayout>
    )
  },
  {
    path: '/dealersforms/test-drive',
    element: (
      <PageLayout>
        <TestDriveForm />
      </PageLayout>
    )
  },
  {
    path: '/dealersforms/mas-info',
    element: (
      <PageLayout>
        <MasInformacionForm />
      </PageLayout>
    )
  }
]);

function App() {
  useEffect(() => {
    var timeoutID:any;
    sendHeight(timeoutID);
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
