import { RegisterOptions } from 'react-hook-form';
import { RadioButtonGroup } from 'src/components';
import { EFormProps } from 'src/types';
import { requiredOption } from 'src/utils';

const options: RegisterOptions = {
  ...requiredOption
};

export function VehiculoNuevoOcasion() {
  return (
    <RadioButtonGroup
      firstOption={{
        name: EFormProps.VN_VO,
        label: 'Vehiculo Nuevo',
        value: 'VN',
        options,
        isChecked: true
      }}
      secondOption={{ name: EFormProps.VN_VO, label: 'Vehiculo Ocasión', value: 'VO', options }}
    />
  );
}
